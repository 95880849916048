import React from 'react';
import Vibeu from '../images/vibeu-logo.png';

const Navbar = () => {
    return (
        <div>
            <header className="p-4 bg-gray-700 text-gray-300">
                <div className="container flex justify-between h-16 mx-auto">
                    <div className="flex">
                        <a rel="noopener noreferrer" href="#" aria-label="Back to homepage" className="flex items-center p-2">
                            <img src={Vibeu} className="h-20" />
                        </a>
                        <ul className="items-stretch hidden space-x-3 lg:flex">
                            <li className="flex">
                                <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Link</a>
                            </li>
                            <li className="flex">
                                <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border- text-orange-500 border-orange-500">Link</a>
                            </li>
                            <li className="flex">
                                <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Link</a>
                            </li>
                            <li className="flex">
                                <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Link</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Navbar
