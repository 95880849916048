import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/LandingPage';
import { Footer, BodyTest, Blog } from './components';

function App() {
  return (
    <div className="App">
     <LandingPage />
     <BodyTest />
     <Blog />
     <Footer />
    </div>
  );
}

export default App;
