import React from 'react';
import { FaServer, FaUsers, FaLaptop, FaDesktop, FaTabletAlt, FaCubes } from "react-icons/fa";


const BodyTest = () => {
    return (
        <div className="bg-gray-900">
            <section className="bg-gray-900 text-gray-300">
                <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl text-gray-300">Welcome to vibeu.tech</h2>
                        <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-400">Your one stop shop for everything IT and development.</p>
                    </div>
                    <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                        <div>
                            <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-gray-300">Information Technology</h3>
                            <p className="mt-3 text-lg text-gray-400">With over 3 years of IT experience running multiple sites and managing hundreds of users, you can trust that the job will be done correctly, with an eye on security.</p>
                            <div className="mt-12 space-y-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                            <FaServer className='h-7 w-7' />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 text-gray-300">Server Management</h4>
                                        <p className="mt-2 text-gray-400">Setup, deploy, and maintain servers in your environment. Onsite setup and remote management solutions will be available.</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                            <FaUsers className='h-7 w-7' />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 text-gray-300">User Management</h4>
                                        <p className="mt-2 text-gray-400">Security is a must, and with a "least access first" mentality, you can trust your users will only have the access they need. No more, no less.</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                            <FaLaptop className='h-7 w-7' />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 text-gray-300">Device Management</h4>
                                        <p className="mt-2 text-gray-400">Trust that all devices, from mobile to desktop workstations and everything in between, are setup securely and correctly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-hidden="true" className="mt-10 lg:mt-0">
                            <img src="https://source.unsplash.com/random/360x480" alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
                        </div>
                    </div>
                    <div>
                        <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
                            <div className="lg:col-start-2">
                                <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-gray-300">Development</h3>
                                <p className="mt-3 text-lg text-gray-400">With over 5 years of web development, application development, and blockchain development, you can be sure your project will be completed to be used on any device, with UI/UX your clients will love.</p>
                                <div className="mt-12 space-y-12">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                                <FaDesktop className='h-7 w-7' />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 text-gray-300">Web Development</h4>
                                            <p className="mt-2 text-gray-400">With experience in JavaScript, React, and others, you can be sure your project is completed and ready to launch as fast as possible. With experience in CosmosDB, MongoDB, and Firebase, we can tailor the information collected and ensure it's stored securely.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                                <FaTabletAlt className='h-7 w-7' />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 text-gray-300">Mobile App Development</h4>
                                            <p className="mt-2 text-gray-400">With experience deploying applications to both the Google Play Store and the Apple App Store, you can trust that everything will be handled to get your application deployed and available with ease to your clients.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md bg-orange-500 dark:text-gray-50">
                                                <FaCubes className='h-7 w-7' />
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 text-gray-300">Blockchain Development</h4>
                                            <p className="mt-2 text-gray-400">With years of experience deploying ERC-20 tokens, ERC-721, and other Ethereum standards, you can trust that your token, NFT, or other dApp is secure and accessible to everyone.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
                                <img src="https://source.unsplash.com/random/361x481" alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default BodyTest
