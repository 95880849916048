// src/components/LandingPage.jsx
import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar'; // Import your Navbar component

const LandingPage = () => {
    const [navbarVisible, setNavbarVisible] = useState(false);

    // Handle scroll to trigger navbar appearance
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setNavbarVisible(true);
            } else {
                setNavbarVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="h-screen w-full bg-gray-900 relative">
            {/* Blinking text with cursor */}
            <div className="flex justify-center items-center h-full">
                <h1 className="text-6xl text-white font-bold">
                    vibeu<span className="text-orange-500">.</span>tech
                    <span className="animate-blink text-white">|</span>
                </h1>
            </div>

            {/* Fading Navbar */}
            <nav
                className={`fixed top-0 left-0 w-full transition-opacity duration-500 ${navbarVisible ? 'opacity-100' : 'opacity-0'
                    }`}
            >
                <Navbar />
            </nav>
        </div>
    );
};

export default LandingPage;