import React from 'react';
import Vibeu from '../images/vibeu-logo.png';

const Footer = () => {
    return (
        <div>
            <footer className="px-4 py-8 bg-gray-700 text-gray-300">
                <div className="container flex flex-wrap items-center justify-center mx-auto space-y-4 sm:justify-between sm:space-y-0">
                    <div className="flex flex-row pr-3 space-x-4 sm:space-x-8">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 rounded-fullbg-violet-600">
                            <img src={Vibeu} className="h-15 rounded-full dark:text-gray-50">
                            </img>
                        </div>
                    </div>
                    <ul className="flex flex-wrap pl-3 space-x-4 sm:space-x-8">
                        <li>
                            <a rel="noopener noreferrer" href="https://github.com/Vybeu">Github</a>
                        </li>
                        <li>
                            0xa3b3E7441D51e15F250CACAd312Dc2fc117560Bb
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Footer
